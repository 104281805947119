<template dense>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon dark rounded @click.stop="usuCheck" v-bind="attrs" v-on="on">
                <v-icon>mdi mdi-{{ label.icon }}</v-icon>
            </v-btn>
        </template>
        <span>{{ label.label }}</span>
    </v-tooltip>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    name: 'Logout',
    methods: mapActions(['usuCheck']),
    computed: {
        label() {
            return localStorage.getItem('tokenAccess') ? { icon: 'logout', label: 'Sair' } : { icon: 'login', label: 'Entrar' }
        }
    }

}
</script>